import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css'],
})
export class HelpComponent implements OnInit {
  INFO: string;
  TEAMS: string;
  constructor() {
    this.INFO =
      'https://dev.azure.com/banistmo/VP%20Servicios%20Corporativos/_wiki/wikis/VP-Servicios-Corporativos.wiki/10805/Como-Agendar-una-Ventana-en-Ambientes-No-productivos';
    this.TEAMS =
      'https://teams.microsoft.com/l/team/19%3a4f3b61cdcf6844148a846ee8e948f1ed%40thread.tacv2/conversations?groupId=8e766072-7018-4b85-a413-775c83daeeae&tenantId=b5e244bd-c492-495b-8b10-61bfd453e423';
  }

  ngOnInit(): void {}
}

